angular.module('services').factory "PrivateAccessFunds",
  ($resource, $q, $http, $timeout) ->
    resource = $resource("/api/v1/private_access_funds/:id/:resource",
      id: "@id"
      status: "@status"
      resource: "@resource"
      query: "@query"
    ,
      getWithoutButter:
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      queryWithoutButter:
        method: "GET"
        isArray: true
        headers:
          "Butter-Bar": false

      update:
        method: "PATCH"

      getPrivateEquityFunds:
        url: "/api/v1/private_access_funds/private_equity_funds"
        method: "GET"
        isArray: true

      getOpenFunds:
        url: "/api/v1/private_access_funds/open_funds"
        method: "GET"
        isArray: true

      sendWelcomeEmail:
        url: "/api/v1/private_access_funds/:id/send_welcome_email"
        method: "PATCH"

      getFormPfClassifications:
        url: '/api/v1/private_access_funds/:id/form_pf_classifications'
        method: 'GET'
        isArray: true

      getAllPafForReverseSolicitation:
        url: '/api/v1/private_access_funds/open_funds_with_country_reverse_inquiry_restrictions'
        method: 'GET'
        isArray: true
      getAllPafsHaveUnusedExceptions:
        url: '/api/v1/private_access_funds/funds_with_unused_exceptions'
        method: 'GET'
        isArray: true
    )

    resource.cache = {}

    resource.getOpenAndVintagePrivateAccessFunds = (params = {}) ->
      params["status"] = "open"
      params["vintage"] = true
      resource.query(
        params
      ).$promise

    resource.getBnyPafs = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/bny_funds").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getSubscriptionReviewPrivateAccessFunds = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/for_subscription_page").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPrivateAccessFundForPafAdmin = (id) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/#{id}/admin_show").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPrivateEquityFundsForPafAdmin = (forRedemptions) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/admin_index", {
        params: { private_equity: true, filter_with_redemptions: forRedemptions }
      }).success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPrivateAccessFundsForPafAdminByGpFirm = (id) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/admin_index?gp_firm_id=#{id}").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPrivateAccessFundsForPafAdmin = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/admin_index").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPrivateAccessFundsForInvestorRelations = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/investor_relations").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getPafForUser = (pafId) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/#{pafId}/for_user").success (response) ->
        dfd.resolve response
        return

      dfd.promise

    resource.getClientManagementForPaf = (pafId) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/#{pafId}/for_paf").success (response) ->
        dfd.resolve response
        return
      dfd.promise

    resource.getPafsForClientManagement = ->
      dfd = $q.defer()

      if resource.cache['getPafsForClientManagement']
        $timeout( -> dfd.resolve resource.cache['getPafsForClientManagement']
        )
      else
        $http.get("/api/v1/private_access_funds/for_client_management").success (response) ->
          resource.cache['getPafsForClientManagement'] = response
          dfd.resolve response
          return

      dfd.promise

    resource.getPafsForContactCard = (contact_id) ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/for_contact_card",
        params:
          contact_id: contact_id
      ).success (response) ->
        dfd.resolve response
        return

      dfd.promise

    resource.getPafsForSalesReporting = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/for_sales_reporting").success (response) ->
        dfd.resolve response
        return

      dfd.promise

    resource.getPafsForManagementReporting = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/for_management_reporting").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    resource.getNonMasterAndFeederPrivateAccessFunds = ->
      dfd = $q.defer()
      $http.get("/api/v1/private_access_funds/for_master_feeder_relationships").success (res) ->
        dfd.resolve res
        return

      dfd.promise

    return resource
