angular.module('services').factory 'TransferService', ($resource) ->
  resource = $resource '/api/v1/transfers/:id',
    id: "@id"
  ,
    reasons:
      method: 'GET'
      url: '/api/v1/transfers/reasons'
      isArray: true

    calculationTypes:
      method: 'GET'
      url: '/api/v1/transfers/calculation_types'
      isArray: false

    calculateAmount:
      method: 'GET'
      url: '/api/v1/transfers/calculate_amount'

    execute:
      url: '/api/v1/transfers/:id/execute'
      method: 'POST'

    transfer_investments:
      url: '/api/v1/transfers/:id/transfer_investments'
      method: 'GET'
      isArray: true

    forSubscriptionReview:
      url: '/api/v1/transfers/for_subscription_review'
      method: 'POST'
      isArray: true

    forClientManagement:
      url: '/api/v1/transfers/for_client_management'
      method: 'POST'
      isArray: true

    update:
      method: 'PATCH'
      isArray: false

  save = (transfer, transfer_investments) ->
    transfer['transfer_investments_attributes'] = transfer_investments
    resource.save({transfer: transfer}).$promise

  remove = (transfer) ->
    resource.delete({id: transfer.id}).$promise

  execute = (transfer) ->
    resource.execute({id: transfer.id}).$promise

  calculateAmount = (params) ->
    resource.calculateAmount(params).$promise

  getReasons = ->
    resource.reasons().$promise

  getCalculationTypes = (pafId) ->
    resource.calculationTypes(paf_id: pafId).$promise

  getTransferInvestments = (transfer) ->
    resource.transfer_investments({id: transfer.id}).$promise

  forSubscriptionReview = (pafId, search, filterOptions, available) ->
    resource.forSubscriptionReview(
      pafId: pafId,
      search: search,
      filterOptions: filterOptions,
      available: available
    ).$promise

  forClientManagement = (pafId, available) ->
    resource.forClientManagement(
      pafId: pafId,
      available: available
    ).$promise

  update = (id, params) ->
    resource.update(
      id: id,
      transfer: params
    ).$promise

  {
    save: save,
    remove: remove,
    execute: execute,
    calculateAmount: calculateAmount,
    getReasons: getReasons,
    getCalculationTypes: getCalculationTypes,
    getTransferInvestments: getTransferInvestments,
    forSubscriptionReview: forSubscriptionReview,
    forClientManagement: forClientManagement
    update: update,
  }
