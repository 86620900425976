angular.module('services').factory "InvestmentService",
  ($resource, $q, $upload) ->
    InvestmentService = ->
      @service = $resource("/api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId",
        privateAccessFundId: "@privateAccessFundId"
        investmentId: "@investmentId"
      ,
        update:
          method: 'PATCH'

        setShareClass:
          url: "/api/v1/investments/:investmentId/set_share_class"
          method: 'PATCH'
          share_class_id: '@share_class_id'

        changeInvestor:
          url: "/api/v1/investments/:investmentId/change_investor"
          method: 'PATCH'

        toggleFunded:
          url: "/api/v1/investments/:investmentId/toggle_funded"
          method: 'PATCH'

        forClientManagement:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/for_client_management"
          method: 'GET'
          isArray: false
          user_id: "@user_id"

        acceptedStats:
          url: "/api/v1/client_management/investments/stats"
          method: 'GET'
          isArray: false
          user_id: "@user_id"

        acceptedInvestments:
          url: "/api/v1/client_management/investments"
          method: 'GET'
          isArray: true
          user_id: "@user_id"

        forClientManagementEmailVerification:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/for_client_management_email_verification"
          method: 'GET'
          isArray: false
          user_id: "@user_id"

        forInvestorRelations:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/for_investor_relations"
          method: 'GET'
          isArray: true
          query: "@query"

        removeRia:
          url: "/api/v1/investments/:investmentId/remove_ria"
          method: 'PATCH'
          isArray: false

        addRia:
          url: "/api/v1/investments/:investmentId/add_ria"
          method: 'PATCH'
          isArray: false
          email: "@email"

        editRia:
          url: "/api/v1/investments/:investmentId/edit_ria"
          method: 'PATCH'
          isArray: false
          email: "@email"

        forContactCard:
          url: "/api/v1/investments/contact_card/:contact_card_id"
          method: 'GET'
          isArray: false

        forContactFund:
          url: "/api/v1/investments/for_contact_fund?contact_card_id=:contactCardId&fund_id=:fundId"
          method: 'GET'
          isArray: false

        createAdditionalContribution:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/create_additional_contribution"
          method: 'PATCH'
          isArray: false

        validateSubDocCode:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/validate_sub_doc_limit_code?sub_doc_code=:sub_doc_code"
          method: 'GET'
          isArray: false

        createForProspectInvestorProfile:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/create_for_prospect_investor_profile"
          method: 'PATCH'
          investment: "@investment"
          isArray: false

        createForInvestorProfile:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/create_for_investor_profile"
          method: 'PATCH'
          investment: "@investment"
          isArray: false

        createForContactCard:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/create_for_contact_card"
          method: 'PATCH'
          investment: "@investment"
          isArray: false

        createForUBS:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/create_for_ubs"
          method: 'PATCH'
          investment: "@investment"
          isArray: false

        validateUBSAccount:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/validate_ubs_account"
          method: 'GET'
          isArray: false

        validateUbsSigner:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/validate_ubs_signer"
          method: 'POST'
          isArray: false

        getRelatedAccounts:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/investor_profile_related_accounts"
          method: 'GET'
          isArray: false

        reject:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/reject"
          method: 'PATCH'
          isArray: false
          resource_name: "@resource_name"
          headers:
            'Butter-Bar': false

        setCommitmentAmount:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_commitment_amount"
          method: 'PATCH'

        setCustodian:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_custodian"
          method: 'PATCH'

        setInvestmentShareClass:
          url: "api/v1/investments/:investmentId/set_investment_share_class"
          method: 'PATCH'

        setPlacementFee:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_placement_fee"
          method: 'PATCH'

        setSubAdvisoryFee:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_sub_advisory_fee"
          method: 'PATCH'

        removeClosingSchedule:
          url: "/api/v1/investments/:investmentId/remove_closing_schedule"
          method: 'PATCH'

        sendDocumentsToIntegrationPartner:
          url: "/api/v1/investments/:investmentId/send_documents_to_integration_partner"
          method: 'PATCH'
          isArray: false

        sendTaxDocuments:
          url: "/api/v1/investments/:investmentId/send_tax_documents"
          method: 'POST'
          isArray: false

        investmentApprovals:
          url: "/api/v1/investments/:investmentId/audit_trail_approval"
          method: 'GET'
          isArray: false

        investmentEvents:
          url: "/api/v1/investments/:investmentId/events"
          method: 'GET'
          isArray: false

        upload:
          url: "/api/v1/investments/:investmentId/upload_document"
          method: 'POST'

        sendSubscription:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/send_subscription"
          method: 'PATCH'

        availableSignMethod:
          url: "/api/v1/investments/:investmentId/available_sign_method"
          method: 'GET'

        cancelSubscription:
          url: "api/v1/investments/:investmentId/cancel_subscription"
          method: 'PATCH'

        previewSubscriptionFile:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/preview_subscription_file"
          method: 'GET'
          headers:
            accept: 'application/pdf'
          responseType: 'arraybuffer',
          transformResponse: (data) ->
            if data
              pdf = new Blob([data],
                type: 'application/pdf'
              )
              return pdf: pdf

        previewSubscriptionAgreement:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/preview_subscription_agreement"
          method: 'GET'

        sendGeneralPartnerCertificate:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/send_general_partner_certificate"
          method: 'PATCH'

        sendTransferDocument:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/send_transfer_document"
          method: 'PATCH'

        logAffirmInvestorIsQp:
          url: "/api/v1/investments/:investmentId/log_affirm_investor_is_qp"
          method: 'PATCH'

        sendRegistrationReminderEmail:
          url: "/api/v1/investments/:investmentId/send_registration_reminder"
          method: 'POST'

        sendInterestedPartyInvites:
          url: "/api/v1/investments/:investmentId/send_interested_party_invitations"
          method: 'POST'

        userPafInvestmentsCount:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/user_paf_investments_count"
          method: 'GET'
          isArray: false

        cancel:
          url: "/api/v1/investments/:investmentId/cancel"
          method: 'PATCH'

        fields:
          url: "/api/v1/investments/fields"
          method: "GET"

        overrideStatus:
          url: "/api/v1/investments/:investmentId/override_status"
          method: 'PATCH'
          status_action: "@status_action"

        addOnInvestments:
          url: '/api/v1/investments/:investmentId/add_on_investments'
          method: 'GET'
          isArray: true

        show:
          url: "/api/v1/investments/:investmentId"
          method: 'GET'

        setSubBooks:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_sub_books"
          method: 'PATCH'

        setClassOfInterests:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/:investmentId/set_class_of_interests"
          method: 'PATCH'

        recallInvestment:
          url: "/api/v1/investments/:investmentId/recall_investment"
          method: 'PATCH'

        overrideApprovalStatus:
          url: "api/v1/investments/:investmentId/override_approval_status"
          method: 'PATCH'

        toggleCloseDateToStatusInvestments:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/toggle_close_date_to_status_investments"
          method: 'PATCH'

        batchToggleCloseDate:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/batch_toggle_close_date"
          method: 'PATCH'

        initialInvestment:
          url: "/api/v1/private_access_funds/:privateAccessFundId/investments/initial_investment"
          method: 'POST'

        initialInvestmentPresent:
          url: "/api/v1/private_access_funds/:privateAccessFundId/investments/initial_investment_present"
          method: 'POST'

        changeSigners:
          url: "/api/v1/investments/:investmentId/change_signers"
          method: 'PATCH'

        ubsJwtTokenValues:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/ubs_jwt_token_values"
          method: 'GET'

        ubsRefreshData:
          url: 'api/v1/investments/:investmentId/ubs_refresh_data'
          method: 'PATCH'

        needsNewAgreementResponses:
          url: "api/v1/investments/:investmentId/needs_new_agreement_responses"
          method: 'GET'

        checkFidelityToken:
          url: "api/v1/investments/:investmentId/check_fidelity_token"
          method: 'GET'

        saveTokenAndSendToFidelity:
          url: "api/v1/private_access_funds/:privateAccessFundId/investments/save_token_and_send_to_fidelity"
          method: 'GET'

        custodianSignatory:
          url: 'api/v1/participant_signatories/participant'
          method: 'GET',
          isArray: false

        validateFundingDate:
          url: "api/v1/investments/validate_funding_date"
          method: 'POST'

        fetchBlocksForInvestment:
          url: "/api/v1/client_management/investments/blocks"
          method: "POST"

        acceptSoftBlocksForInvestment:
          url: "/api/v1/client_management/investments/accept_blocks"
          method: "POST"

        fundMasterIcnServiced:
          url: "api/v1/private_access_funds/:privateAccessFundId/fund_master_fm_fund_icn_serviced"
          method: 'GET'

        eligibleFor2faAuthentication:
          url: "/api/v1/investments/:investmentId/eligible_for_2fa_authentication"
          method: 'GET'

        retrieveSubscriptionDocumentDefinitionId:
          url: "/api/v1/subscription_document_definitions/retrieve_subscription_document_definition"
          method: 'GET'
      )
      return

    InvestmentService::importInvestors = (pafId, files) ->
      dfd = $q.defer()
      params =
        method: 'POST',
        url: "/api/v1/private_access_funds/#{pafId}/import_investors",
        file: files[0]
      $upload.upload(params).success(
        (res) ->
          dfd.resolve(res)
      ).error (errors) ->
        dfd.reject(errors)
      dfd.promise

    InvestmentService::uploadUserDoc = (investment, files) ->
      dfd = $q.defer()
      params =
        method: 'POST',
        url: "/api/v1/investments/#{investment.id}/upload_document",
        file: files[0]
      $upload.upload(params).success(
        (res) ->
          dfd.resolve(res)
      ).error (errors) ->
        dfd.reject(errors)
      dfd.promise

    InvestmentService::uploadGPSignature = (investment, files) ->
      dfd = $q.defer()
      params =
        method: 'POST',
        url: "/api/v1/investments/#{investment.id}/upload_gp_signature",
        file: files[0]
      $upload.upload(params).success(
        (res) ->
          dfd.resolve(res)
      ).error (errors) ->
        dfd.reject(errors)
      dfd.promise

    InvestmentService::uploadTransferDoc = (investment, files) ->
      dfd = $q.defer()
      params =
        method: 'POST',
        url: "/api/v1/investments/#{investment.id}/upload_transfer_document",
        file: files[0]
      $upload.upload(params).success(
        (res) ->
          dfd.resolve(res)
      ).error (errors) ->
        dfd.reject(errors)
      dfd.promise

    InvestmentService::filterByUsername = (documents, filterText) ->
      regExp = new RegExp(filterText.toLowerCase())

      filter = (item) ->
        (if item.contact_card.display_name.toLowerCase().match(regExp) then true else false)
      _.filter documents, filter

    InvestmentService::setFunded = (privateAccessFundId, investmentId, approve) ->
      if approve
        @service.approve privateAccessFundId: privateAccessFundId, investmentId: investmentId, resource_name: 'funded'
      else
        @service.reject privateAccessFundId: privateAccessFundId, investmentId: investmentId, resource_name: 'funded'

    InvestmentService::createAdditionalContribution = (
      privateAccessFundId, investmentId, callValidations,
      amount, date, questionnaire_answers
    ) ->
      @service.createAdditionalContribution(
        privateAccessFundId: privateAccessFundId
        investmentId: investmentId
        call_validations: callValidations
        amount: amount
        date: date
        investment: questionnaire_answers
      ).$promise

    InvestmentService::updateField = (investmentId, field, value) ->
      r = {}
      r[field] = value
      r["field_name"] = field
      @service.updateField(
        investmentId: investmentId
      ,
        r
      ).$promise

    InvestmentService::all = (privateAccessFundId) ->
      @service.query privateAccessFundId: privateAccessFundId

    InvestmentService::forClientManagement = (privateAccessFundId, userId, teamCode, canceled = false) ->
      @service.forClientManagement(
        privateAccessFundId: privateAccessFundId,
        user_id: userId,
        team_code: teamCode,
        canceled: canceled,
        timestamp: Date.now()
      ).$promise

    InvestmentService::acceptedStats = (privateAccessFundId, userId, term) ->
      @service.acceptedStats(
        paf_id: privateAccessFundId,
        user_id: userId,
        search_term: term,
        state: 'accepted',
      ).$promise

    InvestmentService::acceptedInvestments = (privateAccessFundId, userId, page, pageSize, term) ->
      @service.acceptedInvestments(
        paf_id: privateAccessFundId,
        user_id: userId,
        page: page,
        page_size: pageSize,
        search_term: term,
        state: 'accepted',
      ).$promise

    InvestmentService::forClientManagementEmailVerification = (investment) ->
      @service.forClientManagementEmailVerification(
        privateAccessFundId: investment.private_access_fund_id,
        wlp_id: investment.white_label_partner_id,
        investor_profile_id: investment.investor_profile_id,
        investment_id: investment.id,
      ).$promise

    InvestmentService::forInvestorRelations = (privateAccessFundId, query) ->
      @service.forInvestorRelations(privateAccessFundId: privateAccessFundId, query: query).$promise

    InvestmentService::removeRia = (investmentId) ->
      @service.removeRia(investmentId: investmentId).$promise

    InvestmentService::addRia = (investmentId, email) ->
      @service.addRia(investmentId: investmentId, email: email).$promise

    InvestmentService::editRia = (investmentId, email) ->
      @service.editRia(investmentId: investmentId, email: email).$promise

    InvestmentService::forContactCard = (contactCardId) ->
      @service.forContactCard(contactCardId: contactCardId).$promise

    InvestmentService::forContactFund = (contactCardId, fundId) ->
      @service.forContactFund(contactCardId: contactCardId, fundId: fundId).$promise

    InvestmentService::createForProspectInvestorProfile = (privateAccessFundId, newInvestment, fromTransfer = false) ->
      @service.createForProspectInvestorProfile
        privateAccessFundId: privateAccessFundId
        investment: newInvestment,
        from_transfer: fromTransfer

    InvestmentService::createForInvestorProfile = (privateAccessFundId, newInvestment, fromTransfer = false) ->
      @service.createForInvestorProfile
        privateAccessFundId: privateAccessFundId
        investment: newInvestment
        from_transfer: fromTransfer

    InvestmentService::validateSubDocCode = (privateAccessFundId, subDocCode) ->
      @service.validateSubDocCode(privateAccessFundId: privateAccessFundId, sub_doc_code: subDocCode).$promise

    InvestmentService::createForContactCard = (privateAccessFundId, newInvestment, fromTransfer = false) ->
      @service.createForContactCard
        privateAccessFundId: privateAccessFundId
        investment: newInvestment
        from_transfer: fromTransfer

    InvestmentService::create = (
      privateAccessFundId, investment,
      subBookId, custodianAccountId,
      referralCode, wlpId = null, fromTransfer = false
    ) ->
      @service.save
        privateAccessFundId: privateAccessFundId
        investment: investment
        subBookId: subBookId
        custodianAccountId: custodianAccountId
        referralCode: referralCode,
        wlp_id: wlpId,
        from_transfer: fromTransfer

    InvestmentService::createForUBS = (
      privateAccessFundId, investment,
      subBookId, custodianAccountId,
      referralCode, fromTransfer = false,
      acceptedBlocks = false
    ) ->
      @service.createForUBS
        privateAccessFundId: privateAccessFundId
        investment: investment
        subBookId: subBookId
        custodianAccountId: custodianAccountId
        referralCode: referralCode
        from_transfer: fromTransfer
        accepted_blocks: acceptedBlocks

    InvestmentService::validateUBSAccount = ({
      origin,
      privateAccessFundId,
      accountNumber,
      isAccount,
      investmentId,
      runInvestmentValidations,
      suitabilityValidation,
    }) ->
      origin ?= null
      investmentId ?= null
      runInvestmentValidations ?= false
      suitabilityValidation ?= false

      @service.validateUBSAccount(
        origin: origin,
        privateAccessFundId: privateAccessFundId,
        account_number: accountNumber,
        is_account: isAccount,
        id: investmentId,
        run_investment_validations: runInvestmentValidations,
        suitability_validation: suitabilityValidation
      ).$promise

    InvestmentService::validateUbsSigner = ({
      privateAccessFundId,
      responseObject,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      tin,
      dateOfBirth,
      permanentAddressCountry
    }) ->
      @service.validateUbsSigner(
        privateAccessFundId: privateAccessFundId,
        response_object: responseObject,
        first_name: firstName,
        last_name: lastName,
        email_address: emailAddress,
        phone_number: phoneNumber,
        tin: tin,
        date_of_birth: dateOfBirth,
        permanent_address_country: permanentAddressCountry
      ).$promise

    InvestmentService::getRelatedAccounts = (privateAccessFundId, externalId) ->
      @service.getRelatedAccounts(
        privateAccessFundId: privateAccessFundId,
        external_id: externalId
      ).$promise

    InvestmentService::destroy = (privateAccessFundId, investment) ->
      @service.delete(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id
      ).$promise

    InvestmentService::setCommitmentAmount = (privateAccessFundId, investment) ->
      investment.contact_card_attributes = investment.contact_card
      @service.setCommitmentAmount(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        investment: investment
      ).$promise

    InvestmentService::setCustodian = (privateAccessFundId, investment) ->
      if investment.custodian_account
        custodianAccountId = investment.custodian_account.id
      else
        custodianAccountId = undefined

      @service.setCustodian(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        custodian_account_id: custodianAccountId
      ).$promise

    InvestmentService::overrideStatus = (investment, status_action) ->
      @service.overrideStatus(
        investmentId: investment.id
        status_action: status_action
      ).$promise

    InvestmentService::removeClosingSchedule = (investment) ->
      @service.removeClosingSchedule(
        investmentId: investment.id,
        investment: investment
      ).$promise

    InvestmentService::sendDocumentsToIntegrationPartner = (investmentId, documentsIds, integrationPartnerIds) ->
      @service.sendDocumentsToIntegrationPartner(
        investmentId: investmentId,
        documents_ids: documentsIds,
        integration_partner_ids: integrationPartnerIds
      ).$promise

    InvestmentService::investmentApprovals = (investmentId) ->
      @service.investmentApprovals(
        investmentId: investmentId
      ).$promise

    InvestmentService::investmentEvents = (investmentId) ->
      @service.investmentEvents(
        investmentId: investmentId
      ).$promise

    InvestmentService::show = (investmentId) ->
      @service.show(
        investmentId: investmentId
      ).$promise

    InvestmentService::fields = ->
      @service.fields().$promise

    InvestmentService::sendSubscription = (
      privateAccessFundId,
      investment,
      attestationId,
      signerData,
      faOptions,
      commitment,
      resetAgreement
    ) ->
      @service.sendSubscription(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        sign_method: investment.sign_method,
        attestation_id: attestationId,
        signer_data: signerData,
        fa_options: { fa_selection: faOptions.faSelection, selected_signatory_id: faOptions.selectedSignatory?.id, discretionary_phone: faOptions.phone },
        commitment: commitment,
        placement_fee: investment.placement_fee,
        reset_agreement: resetAgreement,
        has_net_commitment: investment.has_net_commitment
      ).$promise

    InvestmentService::previewSubscriptionFile = (privateAccessFundId, investmentId) ->
      @service.previewSubscriptionFile(
        privateAccessFundId: privateAccessFundId,
        investmentId: investmentId,
      ).$promise

    InvestmentService::previewSubscriptionAgreement = (privateAccessFundId, investmentId) ->
      @service.previewSubscriptionAgreement(
        privateAccessFundId: privateAccessFundId,
        investmentId: investmentId,
      ).$promise

    InvestmentService::sendGeneralPartnerCertificate = (gp, name, title, email, signMethod, privateAccessFundId,
      investment) ->
        @service.sendGeneralPartnerCertificate(
          privateAccessFundId: privateAccessFundId,
          investmentId: investment.id,
          gp_signatory_gp: gp,
          gp_signatory_name: name,
          gp_signatory_title: title,
          gp_signatory_email: email,
          sign_method: signMethod
        ).$promise

    InvestmentService::sendTransferDocument = (
      privateAccessFundId,
      investment,
      signerData,
      subscriptionDocumentDefinitionId
    ) ->
      @service.sendTransferDocument(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        sign_method: investment.transfer_sign_method,
        signer_data: signerData
        subscription_document_definition_id: subscriptionDocumentDefinitionId
      ).$promise

    InvestmentService::logAffirmInvestorIsQp = (investmentId) ->
      @service.logAffirmInvestorIsQp(
        investmentId: investmentId
      ).$promise

    InvestmentService::sendRegistrationReminderEmail = (investment) ->
      @service.sendRegistrationReminderEmail(
        investmentId: investment.id
        contactCard: investment.contact_card
      ).$promise

    InvestmentService::sendInterestedPartyInvites = (investment, interestedPartyEmails) ->
      @service.sendInterestedPartyInvites(
        investmentId: investment.id
        emails: interestedPartyEmails
      ).$promise

    InvestmentService::userPafInvestmentsCount = (privateAccessFundId) ->
      @service.userPafInvestmentsCount(privateAccessFundId: privateAccessFundId).$promise

    InvestmentService::cancel = (investmentId) ->
      @service.cancel(
        investmentId: investmentId
      ).$promise

    InvestmentService::setPlacementFee = (investment, placementFee) ->
      investment.placement_fee = placementFee
      @service.setPlacementFee(
        privateAccessFundId: investment.private_access_fund_id,
        investmentId: investment.id,
        investment: investment
      ).$promise

    InvestmentService::setSubAdvisoryFee = (investment, subAdvisoryFee) ->
      investment.sub_advisory_fee = subAdvisoryFee
      @service.setSubAdvisoryFee(
        privateAccessFundId: investment.private_access_fund_id,
        investmentId: investment.id,
        investment: investment
      ).$promise

    InvestmentService::getAddOnInvestments = (investment_id) ->
      @service.addOnInvestments(investmentId: investment_id).$promise

    InvestmentService::availableSignMethod = (investment_id) ->
      @service.availableSignMethod(investmentId: investment_id).$promise

    InvestmentService::setInvestmentShareClass = (investment, investmentShareClassId) ->
      investment.investment_share_class_id = investmentShareClassId
      @service.setInvestmentShareClass(
        investmentId: investment.id,
        investment_share_class_id: investmentShareClassId
      ).$promise

    InvestmentService::setSubBooks = (privateAccessFundId, investment, subBookId, subDocCode = null) ->
      investment.sub_book_id = subBookId
      @service.setSubBooks(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        subBookId: subBookId,
        investment: investment,
        sub_doc_code: subDocCode
      ).$promise

    InvestmentService::setClassOfInterests = (privateAccessFundId, investment) ->
      investment.contact_card_attributes = investment.contact_card
      @service.setClassOfInterests(
        privateAccessFundId: privateAccessFundId,
        investmentId: investment.id,
        investment: investment
      ).$promise

    InvestmentService::recallInvestment = (privateAccessFundId, investmentId) ->
      @service.recallInvestment(
        privateAccessFundId: privateAccessFundId,
        investmentId: investmentId
      ).$promise

    InvestmentService::overrideApprovalStatus = (investmentId, status) ->
      @service.overrideApprovalStatus(
        investmentId: investmentId,
        overrideApprovalStatus: status
      ).$promise

    InvestmentService::toggleFunded = (investmentId, funded) ->
      @service.toggleFunded(
        investmentId: investmentId,
        funded: funded
      ).$promise

    InvestmentService::toggleCloseDateToStatusInvestments = (privateAccessFundId, closeId, statuses,
      removeCloseDate = false) ->
        @service.toggleCloseDateToStatusInvestments(
          privateAccessFundId: privateAccessFundId,
          close_id: closeId,
          statuses: statuses,
          remove_close_date: removeCloseDate
        ).$promise

    InvestmentService::batchToggleCloseDate = (privateAccessFundId, closeId, investmentsIds) ->
      @service.batchToggleCloseDate(
        privateAccessFundId: privateAccessFundId,
        close_id: closeId,
        investments_ids: investmentsIds
      ).$promise

    InvestmentService::setShareClass = (investmentId, shareClassId) ->
      @service.setShareClass(
        investmentId: investmentId,
        share_class_id: shareClassId
      ).$promise

    InvestmentService::initialInvestment = (privateAccessFundId, investment) ->
      @service.initialInvestment(
        privateAccessFundId: privateAccessFundId
        investment: investment
      ).$promise

    InvestmentService::initialInvestmentPresent = (
      privateAccessFundId,
      initialInvestment
    ) ->
      @service.initialInvestmentPresent(
        privateAccessFundId: privateAccessFundId
        investment: initialInvestment
      ).$promise

    InvestmentService::changeInvestor = (investmentId, investorProfileId) ->
      @service.changeInvestor(
        investmentId: investmentId,
        investor_profile_id: investorProfileId
      ).$promise

    InvestmentService::changeSigners = (investmentId, signerIds) ->
      @service.changeSigners(
        investmentId: investmentId
        signer_ids: signerIds
      ).$promise

    InvestmentService::ubsJwtTokenValues = (privateAccessFundId) ->
      @service.ubsJwtTokenValues(
        privateAccessFundId: privateAccessFundId
      ).$promise

    InvestmentService::ubsRefreshData = (investmentId) ->
      @service.ubsRefreshData({ investmentId }).$promise

    InvestmentService::needsNewAgreementResponses = (investmentId) ->
      @service.needsNewAgreementResponses({ investmentId }).$promise

    InvestmentService::cancelSubscription = (investmentId) ->
      @service.cancelSubscription({ investmentId }).$promise

    InvestmentService::checkFidelityToken = (investmentId, fundingDate, orderCondition) ->
      @service.checkFidelityToken(
        investmentId: investmentId,
        funding_date: fundingDate,
        order_condition: orderCondition
      ).$promise

    InvestmentService::saveTokenAndSendToFidelity = (
      privateAccessFundId,
      code,
      fundingDate,
      orderCondition,
      integration,
      investmentId,
      context
    ) ->
      @service.saveTokenAndSendToFidelity(
        privateAccessFundId: privateAccessFundId,
        code: code,
        funding_date: fundingDate,
        order_condition: orderCondition,
        integration: integration,
        investment_id: investmentId,
        context: context
      ).$promise

    InvestmentService::custodianSignatory = (id, custodianId) ->
      @service.custodianSignatory(
        investment_id: id,
        record_id: custodianId,
        record_type: 'Custodian',
        role_type: "custodian_signer"
      ).$promise

    InvestmentService::validateFundingDate = (fundingDate, investmentId) ->
      @service.validateFundingDate(
        funding_date: fundingDate,
        id: investmentId
      ).$promise

    InvestmentService::fetchBlocksForInvestment = (params) ->
      @service.fetchBlocksForInvestment(params).$promise

    InvestmentService::acceptSoftBlocksForInvestment = (investment_id, blocks) ->
      if blocks.length > 0
        @service.acceptSoftBlocksForInvestment({ investment_id, blocks }).$promise
      else
        Promise.resolve()

    InvestmentService::fundMasterIcnServiced = (privateAccessFundId) ->
      @service.fundMasterIcnServiced(
        privateAccessFundId: privateAccessFundId
      ).$promise

    InvestmentService::eligibleFor2faAuthentication = (investmentId) ->
      @service.eligibleFor2faAuthentication(
        investmentId: investmentId
      ).$promise

    InvestmentService::retrieveSubscriptionDocumentDefinitionId = (investmentId) ->
      @service.retrieveSubscriptionDocumentDefinitionId(
        investment_id: investmentId,
        document_type: 'transfer'
      ).$promise

    return new InvestmentService
